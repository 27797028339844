<template>
<div class="quanStats">
  <div class="d-flex justify-start mb-6 text-h6">
      <v-icon left @click="$router.go(-1)"> mdi-arrow-left </v-icon> 
      상세 퀀트통계
  </div>
  <v-progress-circular
      v-if=progressWheel
      indeterminate
      color="primary"
  ></v-progress-circular>

  <span v-html="HTMLcontent"></span>
</div>
</template>

<script>
  import axios from 'axios';
import { title } from 'process';
  import {mapState} from 'vuex';
  export default {
   name: "QuantStats",
   data () {
      return {
        HTMLcontent: null,
        progressWheel: true,
      }
   },

   computed: {
      ...mapState(['title', 'qs_country', 'qs_month', 'qs_data', 'qs_benchmark']) 
   },

   created() {
      this.showQuantStats();
   },
   methods: {
      showQuantStats() {
        var vm = this;    
        const req_data = {'qs_title': this.title, 'qs_country': this.qs_country, 'qs_month': this.qs_month, 
          'qs_data': this.qs_data, 'qs_benchmark': this.qs_benchmark};

        const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};        
        axios.post('/api/etf/backtest/show_stats/', req_data, {headers})
          .then(function(res) {
            vm.HTMLcontent = res.data['qs_html'];
            vm.progressWheel = false;
          })
          .catch(function (err) {
          });  
      }
   }
  }
</script>

<style>
.quanStats{
   padding: 12px;
}
#left{width:620px;margin-right:18px;margin-top:18px;float:left}
#right{width:320px;margin-top:18px;float:right}
</style>
